import PropTypes from 'prop-types';
import TextArea from './TextArea';
import { useField } from 'formik';

export default function TextAreaField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <TextArea
      isValid={!meta.touched || (meta.touched && !meta.error)} // Date time input doesn't seem to register interactions
      error={meta.error}
      label={label}
      {...props}
      {...field}
    />
  );
}

TextAreaField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
