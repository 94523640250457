import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { TIME_FORMAT_UTC_OFFSET_TIMEZONE } from '../../utils/dates';
import ExportController from './ExportController';
import Page from '../../atoms/Page';
import './ExportPage.scss';

function ExportPage({ titleSegments = [] }) {
  const pageTitleSegments = useMemo(() => ['Exports', ...titleSegments], []);
  const timeZoneString = dayjs.tz().format(TIME_FORMAT_UTC_OFFSET_TIMEZONE);

  return (
    <Page className="ExportPage" heading="Data Export" titleSegments={pageTitleSegments}>
      <div className="TimeZoneMessage">Data Selected and Exported in {timeZoneString}</div>
      <ExportController />
    </Page>
  );
}

ExportPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default ExportPage;
