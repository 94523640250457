import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { DATE_FORMAT_DASH, DATE_FORMAT_MONTH_DAY } from '../../utils/dates';
import { CalendarIcon, ExportIcon, Refresh } from '../../atoms/Icons';
import FeedFloDatePicker from '../../molecules/FeedFloDatePicker';
import FeedFloButton from '../../atoms/FeedFloButton';
import FeedFloDropCheckBox from '../../molecules/FeedFloDropCheckBox';
import FeedFloDropDown from '../../atoms/FeedFloDropDown';
import WebAppContext from '../../utils/webAppContext';
import { useAnalysisFilter } from '../../utils/useFeedFrameFilter';
import DocumentFactory from '../../utils/DocumentFactory/factory';
import CSVFeedEventStrategy from '../../utils/DocumentFactory/csvFeedEventStrategy';
import XlsxFeedEventStrategy from '../../utils/DocumentFactory/xlsxFeedEventStrategy';
import CSVFaultCodeStrategy from '../../utils/DocumentFactory/csvFaultCodeStrategy';
import XlsxFaultCodeStrategy from '../../utils/DocumentFactory/xlsxFaultCodeStrategy';
import FeedFloProgress from '../../atoms/FeedFloProgress';

const FARM_GQL = gql`
  query FarmDataQuery {
    farm(order_by: { name: asc }, where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`;

export default function ExportsController() {
  const gqlClient = useApolloClient();
  const [progress, setProgress] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [fileFormat, setFileFormat] = useState(false);
  const [dataType, setDataType] = useState(false);
  const [massUnitSystem, setMassUnitsSystem] = useState(null);
  const [selectedFarmIds, setSelectedFarmIds] = useState([]);
  const [documentFactoryState, setDocumentFactoryState] = useState('ready');

  const { isMetric } = useContext(WebAppContext);

  const today = useMemo(() => dayjs.tz().endOf('day'), []);
  const sevenDaysAgo = useMemo(() => dayjs.tz().subtract(6, 'd').startOf('day'), []);
  const [dateRange, setDateRange] = useState({ from: sevenDaysAgo, to: today });
  const fromLabel = dateRange.from.format(DATE_FORMAT_MONTH_DAY);
  const toLabel = dateRange.to.format(DATE_FORMAT_MONTH_DAY);
  const analysisFilter = useAnalysisFilter();
  const { data } = useQuery(FARM_GQL);
  const farms = data?.farm;

  let errors = {};

  if (!fileFormat) {
    errors.fileFormat = 'Must Select a File Format';
  }
  if (!dataType) {
    errors.dataType = 'Must Pick a Data Type';
  }
  if (!selectedFarmIds || selectedFarmIds.length === 0) {
    errors.barns = 'Must Select At Least One Barn';
  }

  useEffect(() => {
    setMassUnitsSystem(isMetric ? 'metric' : 'imperial');
  }, [isMetric]);

  function onDateChange(e) {
    if (e.dateRange) {
      const newFrom = dayjs.tz(e.dateRange.from);
      const newTo = dayjs.tz(e.dateRange.to);
      setDateRange({ ...dateRange, from: newFrom, to: newTo });
    }

    setShowDatePicker(false);
  }

  function startExport() {
    // check for errors
    if (Object.keys(errors).length > 0) {
      return;
    }

    setDocumentFactoryState('downloading');
    let strategy = null;
    switch (`${fileFormat}|${dataType}`) {
      case 'csv|feedFrame':
        strategy = new CSVFeedEventStrategy(massUnitSystem === 'metric');
        break;
      case 'xlsx|feedFrame':
        strategy = new XlsxFeedEventStrategy(massUnitSystem === 'metric');
        break;
      case 'csv|faultCode':
        strategy = new CSVFaultCodeStrategy();
        break;
      case 'xlsx|faultCode':
        strategy = new XlsxFaultCodeStrategy();
        break;

      default:
        break;
    }

    const factory = new DocumentFactory({
      farmIds: selectedFarmIds,
      startTime: dateRange.from.toDate(),
      stopTime: dateRange.to.toDate(),
      strategy,
      progressCallback: (x) => setProgress(x),
      gqlClient,
      analysisFilter,
    });

    factory
      .start()
      .then(() => {
        const datePart = `${dateRange.from.format(DATE_FORMAT_DASH)}_${dateRange.to.format(DATE_FORMAT_DASH)}`;
        const barnPart = `${selectedFarmIds.length} barns`;
        const dataTypeString = dataType;
        try {
          saveAs(factory.createFile(), `${barnPart}_${dataTypeString}_${datePart}.${fileFormat}`);
        } catch (e) {
          Sentry?.captureException(e);
        }
      })
      .finally(() => {
        setDocumentFactoryState('done');
      });
  }

  const barnListItems = useMemo(() => {
    return farms?.map((f) => {
      return {
        id: f.id,
        name: f.name,
        selected: false,
      };
    });
  }, [farms]);

  const dateRangeInput = (
    <div className={`${barnListItems ? '' : 'disabled'}`}>
      <div
        className="dateRangeButton"
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        <div className="dateRangeButton-icon">
          <CalendarIcon />
        </div>
        <div className="text">{`${fromLabel} - ${toLabel}`}</div>
      </div>
      {showDatePicker && (
        <FeedFloDatePicker
          onClickedOutside={() => setShowDatePicker(false)}
          onChange={(e) => onDateChange(e)}
          from={dateRange.from.toDate()}
          to={dateRange.to.toDate()}
        />
      )}
    </div>
  );

  const formatList = barnListItems
    ? [
        { id: 'xlsx', name: 'Excel .xlsx' },
        { id: 'csv', name: 'Comma Separated Values .csv' },
      ]
    : [];

  const dataTypeList = barnListItems
    ? [
        { id: 'feedFrame', name: 'Feed Events' },
        { id: 'faultCode', name: 'Alerts' },
      ]
    : [];

  const unitList = barnListItems
    ? [
        { id: 'metric', name: 'Metric (kg)', selected: massUnitSystem === 'metric' },
        { id: 'imperial', name: 'Imperial (lbs)', selected: massUnitSystem === 'imperial' },
      ]
    : [];

  if (!analysisFilter) return <div>Loading...</div>;
  return (
    <div className="ExportController">
      <div className="rowContainer">
        <div className="cell">{dateRangeInput}</div>
        <div className="cell">
          <FeedFloDropCheckBox
            id="BarnDropDown"
            list={barnListItems}
            defaultTitle="Barns"
            onChange={(e) => {
              setSelectedFarmIds(e.filter((b) => b.selected).map((b) => b.id));
            }}
          />
          {errors?.barns && <span className="error">{errors.barns}</span>}
        </div>
        <div className="cell">
          <FeedFloDropDown
            list={dataTypeList}
            onChange={(item) => {
              setDataType(item.id);
            }}
            defaultTitle="Data Type"
          />
          {errors?.dataType && <span className="error">{errors.dataType}</span>}
        </div>
        <div className="cell">
          <FeedFloDropDown list={unitList} onChange={(item) => setMassUnitsSystem(item.id)} defaultTitle="Units" />
        </div>
        <div className="cell">
          <FeedFloDropDown
            list={formatList}
            onChange={(item) => {
              setFileFormat(item.id);
            }}
            defaultTitle="File Format"
          />
          {errors?.fileFormat && <span className="error">{errors.fileFormat}</span>}
        </div>

        <div className="cell">
          {documentFactoryState === 'ready' && (
            <FeedFloButton
              onClick={() => startExport()}
              disabled={documentFactoryState !== 'ready' || Object.keys(errors).length > 0}
            >
              <div className="FeedFloButton-icon">
                <ExportIcon />
              </div>
              <div className="text">Export</div>
            </FeedFloButton>
          )}
          {documentFactoryState === 'downloading' && <FeedFloProgress percent={progress} />}
          {documentFactoryState === 'done' && (
            <FeedFloButton onClick={() => setDocumentFactoryState('ready')}>
              <div className="FeedFloButton-icon">
                <Refresh />
              </div>
              <div className="text">Reset</div>
            </FeedFloButton>
          )}
        </div>
      </div>
    </div>
  );
}
