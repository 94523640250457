import PropTypes from 'prop-types';
import { Fzf } from 'fzf';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useCombobox } from 'downshift';
import './Combobox.scss';
import LoadingSkeleton from '../LoadingSkeleton';

function Combobox({
  disabled = false,
  description = '',
  error = '',
  isValid = true,
  items = [],
  label = '',
  loading = false,
  placeholder = '',
  tabIndex = 0,
  value = '',
  // defaults
  defaultIsOpen = false,
  preSelectedItem,
  // classes
  className = '',
  buttonClassName = '',
  descriptionClassName = '',
  errorClassName = '',
  inputClassName = '',
  inputRowClassName = '',
  labelClassName = '',
  listClassName = '',
  listItemClassName = '',
  // handlers
  onBlur = () => {},
  onChange = () => {},
}) {
  const [searchText, setSearchText] = useState('');

  // timed and uses 0-1ms so not worth memoizing right now
  const fzf = new Fzf(items, {
    selector: (item) => item.name,
  });

  const filteredItems = fzf.find(searchText).map((entry) => entry.item);
  const defaultSelectedItem = preSelectedItem || _.find(items, { id: value }) || _.find(items, { value });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setSearchText(inputValue);
    },
    onSelectedItemChange: (changes) => {
      onChange(changes?.selectedItem);
    },
    defaultSelectedItem,
    defaultHighlightedIndex: 0,
    items: filteredItems,
    itemToString(item) {
      return item ? item.name : '';
    },
    defaultIsOpen,
  });

  useEffect(() => {
    selectItem(defaultSelectedItem);
  }, [preSelectedItem, value]);

  if (loading) {
    return (
      <div className={`Combobox ${className}`}>
        {label && <LoadingSkeleton className="Combobox-label--loader" />}
        <LoadingSkeleton className="Combobox-input--loader" />
        {description && <LoadingSkeleton className="Combobox-description--loader" />}
        {error && <LoadingSkeleton className="Combobox-error--loader" />}
      </div>
    );
  }

  return (
    <div className={`Combobox ${className}  ${disabled ? 'Combobox--disabled' : ''}`}>
      <div className={`Comboxbox-field`}>
        {label && (
          <label
            className={`Combobox-label ${!isValid ? 'Combobox-label--error' : ''} ${labelClassName}`}
            {...getLabelProps()}
          >
            {label}
          </label>
        )}
        <div
          className={`Combobox-inputRow ${!isValid ? 'Combobox-inputRow--error' : ''} ${inputRowClassName}`}
          disabled={disabled}
        >
          <input
            placeholder={placeholder}
            className={`Combobox-input ${!isValid ? 'Combobox-input--error' : ''} ${inputClassName} `}
            disabled={disabled}
            {...getInputProps()}
            onBlur={onBlur}
            tabIndex={tabIndex}
          />
          <button
            aria-label="toggle menu"
            className={`Combobox-rightButton ${buttonClassName}`}
            disabled={disabled}
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? <>&#8593;</> : <>&#8595;</>}
          </button>
        </div>
      </div>
      {description && !isOpen && <div className={`Combobox-description ${descriptionClassName}`}>{description}</div>}
      {!isValid && !isOpen && <div className={`Combobox-error ${errorClassName}`}>{error}</div>}
      <ul
        className={`Combobox-list ${isOpen ? 'Combobox-list--open' : 'Combobox-list--closed'} ${listClassName}`}
        {...getMenuProps()}
      >
        {isOpen &&
          filteredItems.map((item, index) => (
            <li
              className={`
                Combobox-listItem 
                ${
                  (selectedItem?.id && selectedItem?.id === item.id) ||
                  (selectedItem?.value && selectedItem?.value === item.value)
                    ? 'Combobox-listItem--selected'
                    : ''
                }
                ${highlightedIndex === index ? 'Combobox-listItem--highlighted' : ''}
                ${listItemClassName}
              `}
              key={item.id || item.value}
              {...getItemProps({ item, index })}
            >
              <span className={`ComboxBox-listItemText`}>{item.name}</span>
            </li>
          ))}
      </ul>
    </div>
  );
}

Combobox.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  disabled: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  error: PropTypes.string,
  errorClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputRowClassName: PropTypes.string,
  isValid: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      //  expect either id or value as the identifier
      // value is used to make it more compatible with Select/Option setups
      id: PropTypes.string,
      value: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  listClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preSelectedItem: PropTypes.any,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
};

export default Combobox;
