import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

import Page from '../../atoms/Page';
import Card from '../../atoms/Card';
import { DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE } from '../../utils/dates';
import TextArea, { TextAreaField } from '../../atoms/TextArea';

import './MockBarnPage.scss';
import { Formik } from 'formik';
import { TextInputField } from '../../atoms/TextInput';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import { MOCK_BARN_PRESETS, MOCK_BARN_PERSET_MAP } from './enum';
import { toast } from 'react-toastify';

const DEFAULT_MOCK_ORG_ID = '2f2a0ed0-528f-4b0e-a1fa-bcb9b47430ec';

const BARN_GQL = gql`
  query MockBarnDataQuery {
    barns: farm(where: { mock_barn_simulation_setting: { settings: { _is_null: false } } }) {
      id
      name
      mock_barn_simulation_state {
        simulation_time_end
      }
      mock_barn_simulation_setting {
        settings
      }
    }
  }
`;

const BARN_SETUP_ONE = gql`
  mutation InsertMockBarnStepOne($barn: farm_insert_input!) {
    __typename
    insert_farm_one(object: $barn) {
      id
      bins {
        id
        name
      }
      bins {
        id
        name
      }
      bin_sets {
        id
      }
      feed_lines {
        id
        device_assignments {
          device {
            id
            serial
          }
        }
      }
    }
  }
`;

const BARN_SETUP_TWO = gql`
  mutation InsertMockBarnStepTwo(
    $bin_feed_lines: [bin_feed_line_insert_input!]!
    $binUpdates: [bin_updates!]!
    $feedLineUpdates: [feed_line_updates!]!
    $mockBarnSettings: mock_barn_simulation_settings_insert_input!
  ) {
    __typename
    update_bin_many(updates: $binUpdates) {
      returning {
        id
      }
    }
    update_feed_line_many(updates: $feedLineUpdates) {
      returning {
        id
      }
    }
    insert_mock_barn_simulation_settings_one(object: $mockBarnSettings) {
      settings
    }
    insert_bin_feed_line(objects: $bin_feed_lines) {
      affected_rows
      returning {
        bin_id
        feed_line_id
      }
    }
  }
`;

const generate_barn_setup_two_variables = (now, stepOneFarmData, settingsTemplate, orgId) => {
  const start_time = now.subtract(6, 'month').unix();
  const barn_id = stepOneFarmData?.id;

  const all_bins = stepOneFarmData.bins;
  const all_feed_lines = stepOneFarmData.feed_lines;
  const bin_sets = stepOneFarmData.bin_sets.map((bin_set, i_bin_set) => {
    // Calculate how many bins to assign to this bin set
    const bin_quotient = Math.floor(all_bins.length / stepOneFarmData.bin_sets.length);
    const bin_remainder = all_bins.length % stepOneFarmData.bin_sets.length;

    // Determine the start and end indices for the bins assigned to this bin set
    const start_index = i_bin_set * bin_quotient + Math.min(i_bin_set, bin_remainder);
    const end_index = start_index + bin_quotient + (i_bin_set < bin_remainder ? 1 : 0);

    // Get the bins for this bin set
    const bins = all_bins.slice(start_index, end_index);

    // Calculate how many feed lines to assign to this bin set
    const feed_line_quotient = Math.floor(all_feed_lines.length / stepOneFarmData.bin_sets.length);
    const feed_line_remainder = all_feed_lines.length % stepOneFarmData.bin_sets.length;

    // Determine the start and end indices for the feed lines assigned to this bin set
    const feed_line_start_index = i_bin_set * feed_line_quotient + Math.min(i_bin_set, feed_line_remainder);
    const feed_line_end_index = feed_line_start_index + feed_line_quotient + (i_bin_set < feed_line_remainder ? 1 : 0);

    // Get the feed lines for this bin set
    const feed_lines = all_feed_lines.slice(feed_line_start_index, feed_line_end_index);

    return {
      id: bin_set.id,
      bins: bins.map((b) => ({
        id: b.id,
      })),
      feed_lines: feed_lines.map((f) => ({
        id: f.id,
        sensor: {
          id: f?.device_assignments[0]?.device?.id,
          serial: f?.device_assignments[0]?.device?.serial,
        },
      })),
    };
  });

  const bin_feed_lines = bin_sets.reduce((arr, b_s) => {
    b_s.bins.forEach((b) => {
      b_s.feed_lines.forEach((fl) => {
        arr.push({
          bin_id: b.id,
          feed_line_id: fl.id,
        });
      });
    });
    return arr;
  }, []);

  const finalSettings = {
    ...settingsTemplate,
    org_id: orgId,
    barn_structure: {
      barn_id: barn_id,
      bin_sets: bin_sets,
    },
    sim_start_time: start_time,
  };

  const binUpdates = [];
  bin_sets.forEach((b_s) => {
    b_s.bins.forEach((b) => {
      binUpdates.push({
        _set: {
          bin_set_id: b_s.id,
        },
        where: {
          id: { _eq: b.id },
        },
      });
    });
  });

  const feedLineUpdates = [];
  bin_sets.forEach((b_s) => {
    b_s.feed_lines.forEach((fl) => {
      feedLineUpdates.push({
        _set: {
          bin_set_id: b_s.id,
        },
        where: {
          id: { _eq: fl.id },
        },
      });
    });
  });

  return {
    mockBarnSettings: {
      barn_id: barn_id,
      settings: finalSettings,
    },
    binUpdates,
    bin_feed_lines,
    feedLineUpdates,
  };
};

function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const generate_barn_setup_one_variables = (now, name, organization_id) => {
  const start_time = now.subtract(6, 'month').unix();

  return {
    barn: {
      organization_id,
      name: name,
      address: '123 Fake St',
      bins: {
        data: [
          {
            name: '1',
            capacity_in_grams: 14514960,
          },
          {
            name: '2',
            capacity_in_grams: 14514960,
          },
          {
            name: '3',
            capacity_in_grams: 14514960,
          },
          {
            name: '4',
            capacity_in_grams: 14514960,
          },
        ],
      },
      bin_sets: {
        data: [
          {
            bin_set_levels: {
              data: [
                {
                  level_in_grams: 0,
                  valid_at: start_time,
                  purpose: 'reset',
                },
              ],
            },
          },
          {
            bin_set_levels: {
              data: [
                {
                  level_in_grams: 0,
                  valid_at: start_time,
                  purpose: 'reset',
                },
              ],
            },
          },
        ],
      },
      feed_lines: {
        data: [
          {
            name: 'North East',
            device_assignments: {
              data: [
                {
                  started_at: start_time,
                  status: 'active',
                  device: {
                    data: {
                      serial: `MOCK-${generateRandomString(6)}`,
                      type: 'FeedLine',
                      hw_version: 'mock',
                    },
                  },
                },
              ],
            },
            feeders: { data: [{ capacity_in_grams: 136077, quantity: 10 }] },
          },
          {
            name: 'North West',
            device_assignments: {
              data: [
                {
                  started_at: start_time,
                  status: 'active',
                  device: {
                    data: {
                      serial: `MOCK-${generateRandomString(6)}`,
                      type: 'FeedLine',
                      hw_version: 'mock',
                    },
                  },
                },
              ],
            },
            feeders: { data: [{ capacity_in_grams: 136077, quantity: 10 }] },
          },
          {
            name: 'South East',
            device_assignments: {
              data: [
                {
                  started_at: start_time,
                  status: 'active',
                  device: {
                    data: {
                      serial: `MOCK-${generateRandomString(6)}`,
                      type: 'FeedLine',
                      hw_version: 'mock',
                    },
                  },
                },
              ],
            },
            feeders: { data: [{ capacity_in_grams: 136077, quantity: 10 }] },
          },
          {
            name: 'South West',
            device_assignments: {
              data: [
                {
                  started_at: start_time,
                  status: 'active',
                  device: {
                    data: {
                      serial: `MOCK-${generateRandomString(6)}`,
                      type: 'FeedLine',
                      hw_version: 'mock',
                    },
                  },
                },
              ],
            },
            feeders: { data: [{ capacity_in_grams: 136077, quantity: 10 }] },
          },
        ],
      },
    },
  };
};

function MockBarnPage({ titleSegments = [] }) {
  const client = useApolloClient();
  const pageTitleSegments = useMemo(() => ['Mock Barns', ...titleSegments], []);
  const { loading, error, data } = useQuery(BARN_GQL, {
    variables: {},
  });
  const [settings, setSettings] = useState();
  const [curOrgId, setCurOrgId] = useState();
  const nowJS = useMemo(() => {
    return dayjs();
  }, []);

  const [insert_barn_step_one] = useMutation(BARN_SETUP_ONE, {
    onCompleted: (insert_barn_step_one_results) => {
      const variables = generate_barn_setup_two_variables(
        nowJS,
        insert_barn_step_one_results.insert_farm_one,
        settings,
        curOrgId,
      );
      insert_barn_step_two({ variables });
    },
    onError: () => {
      toast.warn('Error Occurred Step 1/2', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });
  const [insert_barn_step_two] = useMutation(BARN_SETUP_TWO, {
    onCompleted: (insert_barn_step_two_results) => {
      console.log(insert_barn_step_two_results);
      toast.success('Successfully Updated', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    onError: () => {
      toast.warn('Error Occurred Step 2/2', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return (
    <Page className="MockBarnPage" titleSegments={pageTitleSegments}>
      <h2>Mock Barn Setup </h2>
      <Card className="mockBarnSetupCard">
        <Formik
          onSubmit={(values) => {
            setSettings(JSON.parse(values.settings_json));
            setCurOrgId(values.org_id);
            const variables = generate_barn_setup_one_variables(nowJS, values.name, values.org_id);
            insert_barn_step_one({ variables });
          }}
          initialValues={{ name: `Mock Barn #${generateRandomString(6)}`, org_id: DEFAULT_MOCK_ORG_ID }}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => {
            return (
              <div className="mockSetupForm">
                <TextInputField label={'Name'} name={'name'}></TextInputField>
                <TextInputField label={'Organization ID:'} name={'org_id'}></TextInputField>
                <Select
                  label="Choose a preset"
                  itemList={MOCK_BARN_PRESETS.map((c) => {
                    return { value: c.value, name: c.name };
                  })}
                  value={values.preset}
                  description={errors?.preset}
                  onChange={(e) => {
                    setFieldValue('preset', e.target.value);
                    setFieldValue('settings_json', JSON.stringify(MOCK_BARN_PERSET_MAP[e.target.value].json, null, 4));
                  }}
                />
                <TextAreaField label={'Settings'} name={'settings_json'}></TextAreaField>
                <Button
                  loading={loading}
                  disabled={loading}
                  variant="vivid"
                  color="success"
                  content={'Save'}
                  onClick={handleSubmit}
                />
              </div>
            );
          }}
        </Formik>
      </Card>
      <h2>Mock Barns</h2>
      <Button
        content="Refresh"
        loading={loading}
        onClick={async () => {
          await client.refetchQueries({
            include: [BARN_GQL],
          });
        }}
      ></Button>
      {error && (
        <Card status="error">
          <b>Error:</b>
          <br />
          {JSON.stringify(error)}
        </Card>
      )}
      {data?.barns?.map((b) => {
        return (
          <Card key={b.id} className="barnCard">
            <h4>
              <Link to={`/b/${b.id}`}>{b.name}</Link>
            </h4>
            <br />
            <b>Last Updated:</b>{' '}
            {b?.mock_barn_simulation_state?.simulation_time_end
              ? dayjs.tz(b?.mock_barn_simulation_state?.simulation_time_end * 1000).fromNow() +
                ' - ' +
                dayjs
                  .tz(b?.mock_barn_simulation_state?.simulation_time_end * 1000)
                  .format(DATE_YEAR_MONTH_DAY_DASH_TIME_HOUR_MINUTE)
              : 'Not Running'}
            <TextArea value={JSON.stringify(b?.mock_barn_simulation_setting?.settings, null, 4)}></TextArea>
          </Card>
        );
      })}
    </Page>
  );
}

MockBarnPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default MockBarnPage;
