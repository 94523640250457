import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT_DASH } from '../../utils/dates';
import { convertGramsToSmallUnits, weightSmallUnitLabel } from '../../utils/unitConversion';
import WebAppContext from '../../utils/webAppContext';
import './DeliveryRow.scss';

export default function DeliveryRow({
  createdAt = null,
  orderedAt = null,
  deliveredAt = null,
  externalId = null,
  binName = null,
  status = null,
  weightInGrams = null,
  feedProfileName = null,
  metaData = null,
}) {
  const { isMetric } = useContext(WebAppContext);
  let statusIcon;
  switch (status) {
    case 'delivered':
      statusIcon = '📦';
      break;

    case 'cancelled':
      statusIcon = '❌';
      break;

    default:
      statusIcon = '❓';
      break;
  }
  return (
    <div className={`DeliveryRow status-${status}`}>
      <div className="icon">{statusIcon}</div>
      <div>
        <b>Created: </b>
        {createdAt ? dayjs.tz(createdAt).format(DATE_FORMAT_DASH) : '❓'}
      </div>
      <div>
        <b>Ordered: </b>
        {orderedAt ? dayjs.tz(orderedAt).format(DATE_FORMAT_DASH) : '❓'}
      </div>
      <div>
        <b>Delivered: </b>
        {deliveredAt ? dayjs.tz(deliveredAt).format(DATE_FORMAT_DASH) : '❓'}
      </div>
      <div>
        <b>Bin: </b>
        {binName || '❓'}
      </div>
      <div>
        <b>ID:</b>
        {externalId && ` ${externalId}`}
      </div>
      <div>
        {
          <b>
            {convertGramsToSmallUnits(isMetric, weightInGrams)} {weightSmallUnitLabel(isMetric)}
          </b>
        }
        {` ${feedProfileName}`}
      </div>
      <div className="metaData" title={JSON.stringify(metaData, null, 2)}>
        🗃️
      </div>
    </div>
  );
}

DeliveryRow.propTypes = {
  binName: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  orderedAt: PropTypes.instanceOf(Date),
  deliveredAt: PropTypes.instanceOf(Date),
  externalId: PropTypes.string,
  status: PropTypes.string,
  weightInGrams: PropTypes.number,
  feedProfileName: PropTypes.string,
  metaData: PropTypes.object,
};
