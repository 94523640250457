import { date, number, object, string } from 'yup';
import { purposeList, methodList } from '../../organisms/BinCheckDialog/lists';

export const ErrorMessage = {
  RequiredField: 'Field is required',
  Max255: 'Max field length is 255',
  MustBePositive: 'Value must be positive',
  AtLeastOneDelivery: 'An order must include at least one delivery',
};

const purposeValues = purposeList.map((x) => x.value);
const methodValues = methodList.map((x) => x.value);

export const binCheckSchema = object().shape({
  purpose: string().oneOf(purposeValues).required(ErrorMessage.RequiredField),
  validAtText: date().required(ErrorMessage.RequiredField),
  levelInLargeUnits: number().min(0, ErrorMessage.MustBePositive).required(ErrorMessage.RequiredField),
  method: string().oneOf(methodValues).required(ErrorMessage.RequiredField),
  comment: string().max(255, ErrorMessage.Max255),
});

export const exactCalibrationSchema = object().shape({
  startedAtText: date().required(ErrorMessage.RequiredField),
  endedAtText: date().required(ErrorMessage.RequiredField),
  providedMassInLargeUnits: number().positive(ErrorMessage.MustBePositive).required(ErrorMessage.RequiredField),
});
