import { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { DATE_TIME_DASH } from '../../../../utils/dates';
import Button from '../../../../atoms/Button';
import { DateTimeInputField } from '../../../../atoms/DateTimeInput';
import Select from '../../../../atoms/Select';
import Swatch from '../../../../atoms/Swatch';
import { TextAreaField } from '../../../../atoms/TextArea';
import { TextInputField } from '../../../../atoms/TextInput';
import DeliverySection from './DeliverySection';
import { TripStatus, TripStatusColor } from '../../enums';
import { tripStatusList, orderStatusList } from '../../lists';
import { tripSchema } from '../../validation';
import './RightSidebar.scss';
import { ComboboxField } from '../../../../atoms/Combobox';
import withStaffOnlyIdWrapper from '../../../../utils/withStaffOnlyIdWrapper';

function RightSidebar({
  visible = false,
  loading = false,
  tripID = '',
  isMetric = false,
  barnsAndBins = [],
  barnToBinsMap = {},
  status = TripStatus.Scheduled,
  orderedAt = 0,
  barnID = '',
  deliveredAt = 0,
  deliveries,
  externalID = '',
  comment = '',
  onHideRightSideBar = () => {},
  onSaveTrip = () => {},
}) {
  const today = useMemo(() => dayjs.tz().format(DATE_TIME_DASH), []);

  const FeedOrderDetailsHeading = withStaffOnlyIdWrapper(
    <div className="RightSidebar-heading">Feed Order Details</div>,
  );

  const onSubmit = (values) => {
    onSaveTrip({
      tripID,
      ...values,
    });
    onHideRightSideBar();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="RightSidebar">
      <div className="RightSidebar-detailsContainer">
        <div className="RightSidebar-details">
          <FeedOrderDetailsHeading data={tripID} />
          <Formik
            enableReinitialize={true}
            initialValues={{
              status,
              orderedAt: orderedAt ? dayjs.tz(1000 * orderedAt).format(DATE_TIME_DASH) : today,
              barnID,
              deliveredAt: deliveredAt ? dayjs.tz(1000 * deliveredAt).format(DATE_TIME_DASH) : '',
              externalID,
              comment,
              deliveries,
            }}
            validationSchema={tripSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, setFieldValue, values, errors }) => {
              const onStatusChange = (e) => {
                const newTripStatus = e.target.value;
                setFieldValue('status', newTripStatus);

                // If the trip status is mixed, the status for each delivery can be set individually
                if (newTripStatus === TripStatus.Delivered || newTripStatus === TripStatus.Shipped) {
                  setFieldValue('deliveredAt', values.orderedAt);
                }

                setFieldValue('deliveries', [
                  ...(values.deliveries || []).map((delivery) => {
                    let deliveryDataToSet = {
                      status: newTripStatus === TripStatus.Mixed ? TripStatus.Scheduled : newTripStatus,
                    };
                    if (newTripStatus === TripStatus.Delivered) {
                      deliveryDataToSet['deliveredAt'] = delivery.deliveredAt || delivery.orderedAt;
                      deliveryDataToSet['deliveredMassInLargeUnits'] =
                        delivery.deliveredMassInLargeUnits || delivery.orderedMassInLargeUnits;
                    }
                    return {
                      ...delivery,
                      ...deliveryDataToSet,
                    };
                  }),
                ]);
              };

              const onAddDeliveryClick = () => {
                setFieldValue('deliveries', [
                  {
                    status: orderStatusList.find((status) => status.value === values.status)
                      ? values.status
                      : TripStatus.Scheduled,
                    binID: '',
                    binName: '',
                    orderedAt: 0,
                    orderedMassInLargeUnits: 0,
                    externalID: '',
                    feedProfileName: '',
                    deliveredAt: 0,
                    deliveredMassInLargeUnits: '',
                    comment: '',
                  },
                  ...(values.deliveries || []),
                ]);
              };

              const onRemoveDelivery = (deliveryIndex) => {
                // TODO: Add support for deleting from the DB

                // This removes it from the UI, but does not delete it in the DB
                setFieldValue('deliveries', [...(values.deliveries || []).filter((_, i) => i !== deliveryIndex)]);
              };

              return (
                <>
                  <Select
                    inputClassName="RightSidebar-statusSelect"
                    isValid={!errors?.status}
                    label="Status"
                    left={<Swatch className="RightSidebar-statusSwatch" colour={TripStatusColor[values.status]} />}
                    itemList={tripStatusList}
                    value={values.status}
                    description={errors?.status}
                    onChange={onStatusChange}
                  />
                  <DateTimeInputField name="orderedAt" label="Scheduled Date" />
                  <ComboboxField label="Barn" name="barnID" placeholder="Select a barn" items={barnsAndBins || []} />
                  <DateTimeInputField name="deliveredAt" label="Delivery Date" />
                  <TextInputField name="externalID" label="Order Number" />
                  <TextAreaField name="comment" label="Trip Notes" />
                  <div className="RightSidebar-deliveries">
                    <div
                      className={`RightSidebar-deliveriesLabel ${
                        errors?.deliveries ? 'RightSidebar-deliveriesLabel--error' : ''
                      }`}
                    >
                      Deliveries
                    </div>
                    <div className="RightSidebar-deliveryList">
                      <Button content="Add Delivery" onClick={onAddDeliveryClick} />
                      {(values.deliveries || []).map((delivery, i) => (
                        <DeliverySection
                          key={delivery.id || i}
                          deliveryIndex={i}
                          isMetric={isMetric}
                          barnID={values.barnID}
                          barnToBinsMap={barnToBinsMap}
                          deliveryID={delivery.id}
                          isStatusVisible={values.status === TripStatus.Mixed}
                          status={delivery.status}
                          binID={delivery?.binID}
                          orderedMassInLargeUnits={delivery.orderedMassInLargeUnits.toString()}
                          deliveredMassInLargeUnits={delivery.deliveredMassInLargeUnits.toString()}
                          comment={delivery.comment}
                          isRemoveDeliveryVisible={tripID === ''}
                          errors={typeof errors?.deliveries?.[i] === 'object' ? errors?.deliveries?.[i] : null}
                          onFieldChange={(field, value) => {
                            setFieldValue(`deliveries.${i}.${field}`, value);
                          }}
                          onRemoveDelivery={onRemoveDelivery}
                        />
                      ))}
                    </div>
                    {typeof errors?.deliveries === 'string' ? (
                      <div className="RightSidebar-deliveriesDescription RightSidebar-deliveriesDescription--error">
                        {errors.deliveries}
                      </div>
                    ) : null}
                  </div>
                  <div className="RightSidebar-footer">
                    <Button content="Cancel" onClick={onHideRightSideBar} />
                    <Button
                      loading={loading}
                      disabled={loading}
                      variant="vivid"
                      color="success"
                      content={tripID ? 'Save' : 'Create'}
                      onClick={handleSubmit}
                    />
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

RightSidebar.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  tripID: PropTypes.string,
  isMetric: PropTypes.bool,
  barnsAndBins: PropTypes.array,
  barnToBinsMap: PropTypes.object,
  status: PropTypes.string,
  orderedAt: PropTypes.number,
  barnID: PropTypes.string,
  deliveredAt: PropTypes.number,
  deliveries: PropTypes.array,
  externalID: PropTypes.string,
  comment: PropTypes.string,
  onHideRightSideBar: PropTypes.func,
  onSaveTrip: PropTypes.func,
};

export default RightSidebar;
