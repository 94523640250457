import { useContext } from 'react';
import WebAppContext from '../../webAppContext';
import { convertGramsToLargeUnits } from '../../unitConversion';

const colourPalette = {
  calculated: '#26AF5F',
  projected: '#26AF5F', // same colour as calculated to show it continues
  historicDataColour: '#9be09b', // historic live data aka Calibrated (Live Data)
  deliveries: '#217BCE',
  truthData: '#65C9DA',
  binSetLevel: '#CFD74D', // purpose "Test" (not used for anything but visuals)
  binSetLevelCorrection: '#8B7229', // AKA Reset/Snap
  binSetLevelCalibration: '#4682B4', // purpose "Calibrate"
  calibrations: '#4682B4', // Visualing when a coefficient is used
};

const chartSeriesBase = {
  type: 'scatter',
  name: 'Base Series',
  mode: 'lines',
  line: {
    dash: 'solid',
    width: 3,
    color: '#32CD32', // bright green to stand out
  },
};

// Why is this all stored up here? Easier to quickly compare and contrast styles rather
// than scrolling down through the file to find each function
const chartSeriesSettings = {
  buildTotalCapacitySeries: {
    ...chartSeriesBase,
    name: 'Total Capacity',
    line: {
      ...chartSeriesBase.line,
      color: '#27AE60',
    },
  },
  buildCalculatedBinSetLevelSeries: {
    ...chartSeriesBase,
    name: 'Calibrated (Recalculated Data)',
    // grouped with projections and only visible series from that group
    legendgroup: 'best-data',
    line: {
      ...chartSeriesBase.line,
      color: colourPalette.calculated,
    },
  },
  buildHistoricBinSetLevelSeries: {
    ...chartSeriesBase,
    name: 'Calibrated (Live Data)',
    line: {
      ...chartSeriesBase.line,
      color: colourPalette.historicDataColour, //'#8FBC8F',
    },
  },
  buildProjectedSeries: {
    ...chartSeriesBase,
    // Not in legend, grouped with prod/calculated line
    name: 'Projected Level',
    legendgroup: 'best-data',
    showlegend: false,
    mode: 'lines',
    line: {
      ...chartSeriesBase.line,
      dash: 'dot',
      color: colourPalette.projected,
    },
  },
  buildProjectedOrderedOrderSeries: {
    ...chartSeriesBase,
    name: 'Projected Level',
    // Not in legend, grouped with prod/calculated line
    legendgroup: 'best-data',
    showlegend: false,
    line: {
      ...chartSeriesBase.line,
      dash: 'dot',
      color: '#0ba5ec',
    },
  },
  buildProjectedRecommendedOrderSeries: {
    ...chartSeriesBase,
    name: 'Projected Level',
    // Not in legend, grouped with prod/calculated line
    legendgroup: 'best-data',
    showlegend: false,
    line: {
      ...chartSeriesBase.line,
      dash: 'dot',
      color: '#ddbaf3',
    },
  },
  buildCoefficientSeries: {
    ...chartSeriesBase,
    name: 'Coefficients',
    // off by default with these
    visible: 'legendonly',
    showlegend: true,
    mode: 'lines+markers+text',
    line: {
      ...chartSeriesBase.line,
      color: colourPalette.calibrations,
    },
    marker: {
      size: 10,
      symbol: 'arrow-bar-right-open',
      line: {
        width: 1,
      },
    },
    textposition: 'top center',
  },
  buildDeliverySeries: {
    ...chartSeriesBase,
    name: 'Deliveries',
    legendgroup: 'deliveries',
    visible: 'legendonly',
    mode: 'lines+markers',
    line: {
      ...chartSeriesBase.line,
      color: colourPalette.deliveries,
    },
    marker: {
      size: 8,
      symbol: 'line-ew-open',
      line: {
        width: 3,
      },
    },
  },
  // buildBinSetLevelSeries is all dynamic so it's defined in its function
};

export default function useBinInventoryChartSeries() {
  const { isMetric } = useContext(WebAppContext);

  const buildTotalCapacitySeries = (totalCapacity = 0, earliestOccurredAt = null, latestOccurredAt = null) => {
    const trace = { x: [], y: [] };

    if (totalCapacity && earliestOccurredAt && latestOccurredAt) {
      trace.x.push(earliestOccurredAt);
      trace.x.push(latestOccurredAt);
      trace.y.push(convertGramsToLargeUnits(isMetric, totalCapacity));
      trace.y.push(convertGramsToLargeUnits(isMetric, totalCapacity));
    }

    return {
      ...chartSeriesSettings.buildTotalCapacitySeries,
      ...trace,
    };
  };

  const _buildBinSetLevelSeriesTrace = (binSetLevelTimeSeries = []) => {
    const trace = binSetLevelTimeSeries.reduce(
      (out, point, currentIndex, array) => {
        out.x.push(point.occurredAt);
        out.y.push(convertGramsToLargeUnits(isMetric, point.value));
        if (array.length > currentIndex + 1) {
          out.x.push(array[currentIndex + 1].occurredAt);
          out.y.push(convertGramsToLargeUnits(isMetric, point.value));
        }
        return out;
      },
      { x: [], y: [] },
    );
    return trace;
  };

  const buildCalculatedBinSetLevelSeries = (binSetLevelTimeSeries = []) => {
    // aka the current best
    const trace = _buildBinSetLevelSeriesTrace(binSetLevelTimeSeries);

    return {
      ...chartSeriesSettings.buildCalculatedBinSetLevelSeries,
      legendgroup: 'recalculated',
      ...trace,
    };
  };

  const buildHistoricBinSetLevelSeries = (binSetLevelTimeSeries = []) => {
    const historicTrace = _buildBinSetLevelSeriesTrace(binSetLevelTimeSeries);

    return {
      ...chartSeriesSettings.buildHistoricBinSetLevelSeries,
      ...historicTrace,
    };
  };

  const buildProjectedSeries = (predictionTimeSeries = []) => {
    const trace = predictionTimeSeries.reduce(
      (out, point) => {
        // Prevent the calculated and projected series from overlapping
        out.x.push(point.occurredAt);
        out.y.push(convertGramsToLargeUnits(isMetric, point.value));

        return out;
      },
      { x: [], y: [] },
    );

    return {
      ...chartSeriesSettings.buildProjectedSeries,
      legendgroup: 'recalculated',
      x: trace.y[0] > 0 ? trace.x : [],
      y: trace.y[0] > 0 ? trace.y : [],
    };
  };

  const buildProjectedOrderedOrderSeries = (rawOrders = [], predictionTimeSeries = [], maxTimestamp) => {
    return buildProjectedOrderSeries(
      rawOrders,
      predictionTimeSeries,
      chartSeriesSettings.buildProjectedOrderedOrderSeries,
      maxTimestamp,
    );
  };
  const buildProjectedRecommendedOrderSeries = (rawOrders = [], predictionTimeSeries = [], maxTimestamp) => {
    return buildProjectedOrderSeries(
      rawOrders,
      predictionTimeSeries,
      chartSeriesSettings.buildProjectedRecommendedOrderSeries,
      maxTimestamp,
    );
  };

  const buildProjectedOrderSeries = (
    rawOrders = [],
    predictionTimeSeries = [],
    chartSeriesSettings,
    maxTimestamp = Number.MAX_SAFE_INTEGER,
  ) => {
    const predictionSlope =
      (predictionTimeSeries?.at(-1)?.value - predictionTimeSeries?.at(0)?.value) /
      (predictionTimeSeries?.at(-1)?.occurredAt.getTime() / 1000 -
        predictionTimeSeries?.at(0)?.occurredAt.getTime() / 1000);

    // We can only work with negative slopes, and we don't want to place any points if there are no orders.
    if (Number.isNaN(predictionSlope) || 0 <= predictionSlope || 0 === rawOrders.length) return {};

    const orders = rawOrders.sort((a, b) => a.timestamp - b.timestamp).filter((o) => o.timestamp < maxTimestamp);

    const startPoint = {
      timestamp: predictionTimeSeries.at(0).occurredAt.getTime() / 1000,
      value: predictionTimeSeries.at(0).value,
    };

    const trace = orders.reduce(
      (trace, order, index) => {
        // The previous point inserted to our trace if there is one, otherwise startPoint.
        const prevPoint = 0 === index ? startPoint : { timestamp: trace.x.at(-1), value: trace.y.at(-1) };

        const timestampDiff = order.timestamp - prevPoint.timestamp;
        // Use the rate of change to calculate where our prediction line would be before the delivery is factored in.
        const predictedLevelBeforeDelivery = prevPoint.value + predictionSlope * timestampDiff;

        if (predictedLevelBeforeDelivery < 0) {
          // Negative feed values don't exist, but late deliveries do.
          // Add an extra point to make line go horizontal when it hits the X axis.
          const xIntercept = -prevPoint.value / predictionSlope + prevPoint.timestamp;
          trace.x.push(xIntercept);
          trace.y.push(0);

          // Add a point to the X axis when the order will occur.
          trace.x.push(order.timestamp);
          trace.y.push(0);

          // Add the predicted point after the order, joined by a vertical line.
          trace.x.push(order.timestamp);
          trace.y.push(order.value);
        } else {
          const predictedLevelAfterDelivery = predictedLevelBeforeDelivery + order.value;

          // Add the predicted before the order.
          trace.x.push(order.timestamp);
          trace.y.push(predictedLevelBeforeDelivery);

          // Add the predicted point after the order, joined by a vertical line.
          trace.x.push(order.timestamp);
          trace.y.push(predictedLevelAfterDelivery);
        }

        return trace;
      },
      { x: [], y: [] },
    );

    // Add final point to bring the line segment to the X axis.
    //   X-intercept: 0 === m(x - x0) + b
    //                x = -b/m + x0
    const xIntercept = -trace.y.at(-1) / predictionSlope + trace.x.at(-1);
    if (xIntercept < maxTimestamp) {
      trace.x.push(xIntercept);
      trace.y.push(0);
    } else {
      const yIntercept = trace.y.at(-1) + (maxTimestamp - trace.x.at(-1)) * predictionSlope;
      trace.x.push(maxTimestamp);
      trace.y.push(yIntercept);
    }
    // Convert X values from UNIX timestamps to Date objects.
    trace.x = trace.x.map((x) => new Date(x * 1000));
    // Convert Y values from grams to large units.
    trace.y = trace.y.map((y) => convertGramsToLargeUnits(isMetric, y));

    return {
      ...chartSeriesSettings,
      x: trace.x,
      y: trace.y,
    };
  };

  const buildCoefficientSeries = (calibrations = []) => {
    // The Coefficient Series is just for debugging/internal use
    const traces = calibrations.reduce(
      (out, cal) => {
        // Displaying the start and puting an |>| so it's clear it's from that point onwards
        out.x.push(cal.startedAt);
        out.x.push(null);

        // positioned at a somewhat arbitrary height, should be somewhere near the bin levels
        out.y.push(10 * cal.value); // 10 <unit> (tons/tonnes)
        out.y.push(null);

        // labels float below with coefficient value for now
        out.text.push(cal.value);
        out.text.push(null);
        return out;
      },
      { x: [], y: [], text: [] },
    );

    return {
      ...chartSeriesSettings.buildCoefficientSeries,
      ...traces,
    };
  };
  const buildDeliverySeries = (deliveries = [], orders = []) => {
    // Map array of delivery value/offset to timestamp keys.
    const deliveriesByTimestamp = [...deliveries, ...orders].reduce((deliveriesByTimestamp, { timestamp, value }) => {
      // Offsets keep delivery lines from overlapping when sharing a timestamp.
      const offset = deliveriesByTimestamp[timestamp]?.at(-1)?.value || 0;

      if (!Array.isArray(deliveriesByTimestamp[timestamp])) {
        deliveriesByTimestamp[timestamp] = [];
      }

      deliveriesByTimestamp[timestamp].push({ value, offset });

      return deliveriesByTimestamp;
    }, {});

    // Break deliveriesByTimestamp into one or more array series.
    // Additional series represent delivery lines that would overlap.
    const deliverySeries = [];
    Object.entries(deliveriesByTimestamp).forEach(([timestamp, values]) => {
      values.forEach(({ value, offset }, index) => {
        if (!Array.isArray(deliverySeries[index])) {
          deliverySeries[index] = [];
        }
        deliverySeries[index].push({ timestamp, value, offset });
      });
    });

    // Convert each deliverySeries into scatterplot line data.
    const traces = deliverySeries.map((deliveries) =>
      deliveries.reduce(
        (out, delivery) => {
          out.x.push(new Date(delivery.timestamp * 1000));
          out.x.push(new Date(delivery.timestamp * 1000));
          out.x.push(null);
          out.y.push(convertGramsToLargeUnits(isMetric, delivery.offset));
          out.y.push(convertGramsToLargeUnits(isMetric, delivery.value + delivery.offset));
          out.y.push(null);
          return out;
        },
        { x: [], y: [] },
      ),
    );

    // Returns an array of one or more line series.
    // Additional series are given distinct colours and widths to help them stand out.
    return traces.map((trace, index) => {
      return {
        ...chartSeriesSettings.buildDeliverySeries,
        showlegend: 0 === index,
        ...trace,
      };
    });
  };

  const buildTruthDataSeries = (knownBinLevels = []) => {
    return {
      type: 'scatter',
      name: 'Truth Data',
      line: {
        dash: 'solid',
        width: 3,
        color: colourPalette.truthData,
      },
      x: knownBinLevels.map((kbl) => new Date(kbl.valid_at * 1000)),
      y: knownBinLevels.map((kbl) => convertGramsToLargeUnits(isMetric, kbl.level_in_grams, 4)),
    };
  };

  const buildBinSetLevelSeries = (binSetLevels = [], type = 'test') => {
    if (!binSetLevels.length) {
      return {};
    }

    const trace = binSetLevels.reduce(
      (out, binSetLevel) => {
        out.x.push(binSetLevel.valid_at * 1000);
        out.x.push(null);
        out.y.push(convertGramsToLargeUnits(isMetric, binSetLevel.level_in_grams));
        out.y.push(null);
        out.error_y.array.push(convertGramsToLargeUnits(isMetric, binSetLevel.expected_deviation_in_grams));
        out.error_y.array.push(null);

        return out;
      },
      {
        x: [],
        y: [],
        error_y: {
          type: 'data',
          array: [],
          thickness: 3,
          visible: true,
        },
      },
    );

    let colourToUse = colourPalette.binSetLevel;
    let name = 'Bin Set Levels';

    if (type === 'reset') {
      colourToUse = colourPalette.binSetLevelCorrection;
      name = 'Bin Level Reset';
    } else if (type === 'calibrate') {
      colourToUse = colourPalette.binSetLevelCalibration;
      name = 'Bin Level Calibration';
    } else if (type === 'test') {
      colourToUse = colourPalette.binSetLevel;
      name = 'Bin Level Test';
    } else {
      name = `Bin Level ${type.charAt(0).toUpperCase() + type.slice(1)}.`;
    }

    return {
      ...chartSeriesBase,
      legendgroup: 'Bin Levels',
      name,
      line: {
        ...chartSeriesBase.line,
        color: colourToUse,
      },
      ...trace,
    };
  };

  return {
    buildCalculatedBinSetLevelSeries,
    buildHistoricBinSetLevelSeries,
    buildProjectedSeries,
    buildCoefficientSeries,
    buildProjectedOrderedOrderSeries,
    buildProjectedRecommendedOrderSeries,
    buildDeliverySeries,
    buildTruthDataSeries,
    buildBinSetLevelSeries,
    buildTotalCapacitySeries,
  };
}
