export const MOCK_BARN_PRESETS = [
  {
    value: 'none',
    name: 'None',
    json: {},
  },
  {
    value: 'good',
    name: 'The Good',
    json: {
      timezone: 'America/Winnipeg',
      feeder_capacity: 136000,
      mill_order_size: 10886220,
      mill_order_delay: 172800,
      barn_num_bin_sets: 2,
      bin_blockage_rate: 5e-9,
      bin_starting_level: 0,
      time_steps_per_hour: 15,
      barn_num_bins_per_set: 2,
      feeder_starting_level: 0,
      feedline_failure_rate: 5e-8,
      feedline_max_run_time: 14400,
      animalSink_pickup_days: 20,
      animal_starting_weight: 25000,
      animal_stop_eating_hour: 21,
      feedline_max_g_per_hour: 1300000,
      animalSink_target_weight: 115000,
      animal_start_eating_hour: 6,
      feeder_trigger_threshold: 100000,
      sensor_inavtive_threshold: 43200,
      animalSource_drop_off_days: 5,
      barn_num_feedlines_per_set: 2,
      animal_feed_conversion_ratio: 0.3,
      bin_dispensing_rate_per_hour: 1000000,
      per_feedline_check_skip_rate: 0.2,
      barn_num_feeders_per_feedline: 10,
      caretaker_bin_visit_skip_rate: 0,
      animalSource_animal_batch_size: 2400,
      caretaker_bin_measurement_std_dev: 0,
      caretaker_bin_nearing_empty: 2000000,
      caretaker_bin_empty: 1000,
      animal_body_weight_consumed_per_day: 0.08,
      bin_dispensing_rate_per_hour_std_div: 0,
      animalSource_expetected_growth_period: 9676800,
      caretaker_bin_visit_bin_check_skip_rate: 0,
    },
  },
  {
    value: 'bad',
    name: 'The Bad',
    json: {
      timezone: 'America/Winnipeg',
      feeder_capacity: 136000,
      mill_order_size: 10886220,
      mill_order_delay: 172800,
      barn_num_bin_sets: 2,
      bin_blockage_rate: 0.005,
      bin_starting_level: 0,
      time_steps_per_hour: 15,
      barn_num_bins_per_set: 2,
      feeder_starting_level: 0,
      feedline_failure_rate: 0.005,
      feedline_max_run_time: 14400,
      animalSink_pickup_days: 20,
      animal_starting_weight: 25000,
      animal_stop_eating_hour: 21,
      feedline_max_g_per_hour: 1300000,
      animalSink_target_weight: 115000,
      animal_start_eating_hour: 6,
      feeder_trigger_threshold: 100000,
      sensor_inavtive_threshold: 43200,
      animalSource_drop_off_days: 5,
      barn_num_feedlines_per_set: 2,
      animal_feed_conversion_ratio: 0.3,
      bin_dispensing_rate_per_hour: 1000000,
      per_feedline_check_skip_rate: 0.2,
      barn_num_feeders_per_feedline: 10,
      caretaker_bin_visit_skip_rate: 0.01,
      animalSource_animal_batch_size: 2400,
      caretaker_bin_measurement_std_dev: 4000000,
      caretaker_bin_nearing_empty: 2000000,
      caretaker_bin_empty: 1000,
      animal_body_weight_consumed_per_day: 0.08,
      bin_dispensing_rate_per_hour_std_div: 0,
      animalSource_expetected_growth_period: 9676800,
      caretaker_bin_visit_bin_check_skip_rate: 0.1,
    },
  },
  {
    value: 'ugly',
    name: 'The Ugly',
    json: {
      timezone: 'America/Winnipeg',
      feeder_capacity: 136000,
      mill_order_size: 10886220,
      mill_order_delay: 172800,
      barn_num_bin_sets: 2,
      bin_blockage_rate: 5e-9,
      bin_starting_level: 0,
      time_steps_per_hour: 15,
      barn_num_bins_per_set: 2,
      feeder_starting_level: 0,
      feedline_failure_rate: 5e-8,
      feedline_max_run_time: 14400,
      animalSink_pickup_days: 20,
      animal_starting_weight: 25000,
      animal_stop_eating_hour: 21,
      feedline_max_g_per_hour: 1300000,
      animalSink_target_weight: 115000,
      animal_start_eating_hour: 6,
      feeder_trigger_threshold: 100000,
      sensor_inavtive_threshold: 43200,
      animalSource_drop_off_days: 5,
      barn_num_feedlines_per_set: 2,
      animal_feed_conversion_ratio: 0.3,
      bin_dispensing_rate_per_hour: 1000000,
      per_feedline_check_skip_rate: 0.2,
      barn_num_feeders_per_feedline: 10,
      caretaker_bin_visit_skip_rate: 0,
      animalSource_animal_batch_size: 2400,
      caretaker_bin_measurement_std_dev: 4000000,
      caretaker_bin_nearing_empty: 2000000,
      caretaker_bin_empty: 1000,
      animal_body_weight_consumed_per_day: 0.08,
      bin_dispensing_rate_per_hour_std_div: 0,
      animalSource_expetected_growth_period: 9676800,
      caretaker_bin_visit_bin_check_skip_rate: 0,
    },
  },
];

export const MOCK_BARN_PERSET_MAP = MOCK_BARN_PRESETS.reduce((map, curr) => {
  map[curr.value] = curr;
  return map;
}, {});
