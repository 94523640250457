import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getFaultName } from '@norimaconsulting/fault-codes';
import { ALERTABLE_FAULT_CODES } from '../index';
import {
  GetFarmDataQuery,
  GetFeedEventCountQuery,
  GetFaultCountQuery,
  GetFeedFrameExportQuery,
  GetFaultCodeExportQuery,
} from './queries';

const REQUEST_LIMIT = 500;

class Factory {
  constructor({ farmIds, startTime, stopTime, gqlClient, strategy, progressCallback, analysisFilter }) {
    this.strategy = strategy;
    this.progressCallback = progressCallback;
    this.farmIds = farmIds;
    this.farmData = [];
    this.eventData = [];
    this.startTime = dayjs.tz(startTime).unix();
    this.stopTime = dayjs.tz(stopTime).unix();
    this.gqlClient = gqlClient;
    this.analysisFilter = analysisFilter;
  }

  async start() {
    if (!this.strategy || !this.strategy.processFaultCodes) {
      Sentry?.captureMessage('DocumentFactory does not have any strategies provided');
    }

    this.progressCallback(0);

    this.farmData = await this.getFarmData(this.farmIds);

    if (this.strategy?.processFeedFramePage) {
      await this.downloadFeedFrames();
    }
    if (this.strategy?.processFaultCodes) {
      await this.downloadFaultCodes();
    }

    if (!this.strategy?.processFaultCodes && !this.strategy?.processFeedFramePage) {
      console.error('A strategy should be defined');
      const progressTimerId = setTimeout(() => {
        // Re-enable button after error disappearins
        this.progressCallback(1);
      }, 4000);
      toast.error('An error occurred. Have you selected an option for each field?', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          clearTimeout(progressTimerId);
          this.progressCallback(1);
        },
      });
    }
  }

  safeGetFaultName(code) {
    try {
      return getFaultName(code);
    } catch (error) {
      return code;
    }
  }

  async downloadFaultCodes() {
    let downloadedDataPoints = 0;

    const totalNumberOfDataPoints = await this.getNumFaultCodes();
    this.strategy.setFarmObjects(this.farmData);

    for (const farm of this.farmData) {
      for (const line of farm.feed_lines) {
        let currEventData = null;
        let offset = 0;
        do {
          // eslint-disable-next-line no-await-in-loop
          currEventData = await this.getPageOfFaultCodesForLine(line.id, REQUEST_LIMIT, offset);
          this.strategy.processFaultCodes(farm.id, line.id, currEventData);
          offset += currEventData.length;
          downloadedDataPoints += currEventData.length;
          this.progressCallback(downloadedDataPoints / totalNumberOfDataPoints);
        } while (currEventData.length >= REQUEST_LIMIT);
      }
    }
  }

  async downloadFeedFrames() {
    let downloadedDataPoints = 0;

    const totalNumberOfDataPoints = await this.getNumFeedEvents();
    this.strategy.setFarmObjects(this.farmData);

    for (const farm of this.farmData) {
      for (const line of farm.feed_lines) {
        let currEventData = null;
        let offset = 0;
        do {
          // eslint-disable-next-line no-await-in-loop
          currEventData = await this.getPageOfFeedFrameDataForLine(line.id, REQUEST_LIMIT, offset);
          const filteredEventData = currEventData.filter((frame) => {
            return frame.mass;
          });
          this.strategy.processFeedFramePage(farm.id, line.id, filteredEventData);
          offset += currEventData.length;
          downloadedDataPoints += currEventData.length;
          this.progressCallback(downloadedDataPoints / totalNumberOfDataPoints);
        } while (currEventData.length >= REQUEST_LIMIT);
      }
    }
  }

  async getNumFeedEvents() {
    const lineReducer = (arr, farm) => arr.concat(farm.feed_lines);
    const idReducer = (arr, line) => arr.concat(line.id);
    const feedLineIDs = this.farmData.reduce(lineReducer, []).reduce(idReducer, []);

    return this.gqlClient
      .query({
        query: GetFeedEventCountQuery,
        variables: {
          feedLineIDs,
          startedAt: this.startTime,
          endedAt: this.stopTime,
        },
      })
      .then((data) => {
        return data.data.feed_frame_aggregate.aggregate.count;
      });
  }

  async getNumFaultCodes() {
    const lineReducer = (arr, farm) => arr.concat(farm.feed_lines);
    const idReducer = (arr, line) => arr.concat(line.id);
    const feedLineIDs = this.farmData.reduce(lineReducer, []).reduce(idReducer, []);

    return this.gqlClient
      .query({
        query: GetFaultCountQuery,
        variables: {
          feedLineIDs,
          startedAt: this.startTime,
          endedAt: this.stopTime,
          faultCodes: ALERTABLE_FAULT_CODES,
        },
      })
      .then((data) => {
        return data.data.fault_aggregate.aggregate.count;
      });
  }

  createFile() {
    return this.strategy?.createFile();
  }

  async getPageOfFeedFrameDataForLine(feedLineID, limit, offset) {
    return this.gqlClient
      .query({
        query: GetFeedFrameExportQuery,
        variables: {
          feedLineID,
          startedAt: this.startTime,
          endedAt: this.stopTime,
          limit,
          offset,
          feedFrameAnalysisWhere: this.analysisFilter,
        },
      })
      .then((data) => {
        return data.data.feed_frame.map((frame) => {
          return {
            s: frame.started_at,
            e: frame.ended_at,
            mass: frame.feed_frame_analyses[0]?.latest_estimated_mass_moved_in_grams,
          };
        });
      });
  }

  async getPageOfFaultCodesForLine(feedLineID, limit, offset) {
    return this.gqlClient
      .query({
        query: GetFaultCodeExportQuery,
        variables: {
          feedLineID,
          startedAt: this.startTime,
          endedAt: this.stopTime,
          faultCodes: ALERTABLE_FAULT_CODES,
          limit,
          offset,
        },
      })
      .then((data) => {
        return data.data.fault.map((f) => {
          return {
            s: f.started_at,
            e: f?.ended_at,
            faultText: this.safeGetFaultName(f.code),
          };
        });
      });
  }

  getFarmData(farmIDs) {
    return this.gqlClient
      .query({
        query: GetFarmDataQuery,
        variables: {
          farmIDs,
        },
      })
      .then((response) => {
        return response.data.farm;
      });
  }
}

export default Factory;
