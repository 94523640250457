import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { algorithmVersionAtom } from '../../utils/jotaiAtoms';
import Page from '../../atoms/Page';
import StatusBadge from '../../atoms/StatusBadge';
import LoadingSkeleton from '../../atoms/LoadingSkeleton';
import { useCensor, censorTypes } from '../../utils/hooks/useCensor';
import './DeviceStatusPage.scss';
import useUser from '../../utils/hooks/useUser';

const DEVICE_GQL = gql`
  query DeviceQuery(
    $hwCompare: String_comparison_exp
    $serialCompare: String_comparison_exp
    $limit: Int = 1
    $offset: Int = 0
    $startTime: bigint
    $endTime: bigint
    $sha: String
  ) {
    device(
      limit: $limit
      offset: $offset
      where: {
        hw_version: $hwCompare
        serial: $serialCompare
        device_assignments: {
          deleted_at: { _is_null: true }
          started_at: { _is_null: false }
          ended_at: { _is_null: true }
        }
      }
      order_by: { serial: asc }
    ) {
      id
      serial
      hw_version
      device_assignments(order_by: { started_at: desc }, limit: 1) {
        feed_line {
          name
          id
          farm {
            name
            id
          }
        }
      }
      lastTransaction: transactions(
        where: { occured_at: { _gte: $startTime } }
        order_by: { occured_at: desc }
        limit: 1
      ) {
        occured_at
      }
      lastData: device_data(where: { created_at: { _gte: $startTime } }, order_by: { created_at: desc }, limit: 1) {
        created_at
        started_at
        ended_at
      }
      hw_faults_agg: faults_aggregate(where: { ended_at: { _is_null: false }, code: { _gte: 4000, _lte: 4999 } }) {
        aggregate {
          count
        }
      }
      total_feed_frames: feed_frames_aggregate(
        where: { started_at: { _gte: $startTime }, ended_at: { _lte: $endTime } }
      ) {
        aggregate {
          count
        }
      }
      finished_feed_frames: feed_frames_aggregate(
        where: {
          started_at: { _gte: $startTime }
          ended_at: { _lte: $endTime }
          feed_frame_analyses: { algorithm_version: { _eq: $sha } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

function DeviceStatusPage({ titleSegments = [] }) {
  const limit = 1000;
  const pageTitleSegments = useMemo(() => ['Devices', ...titleSegments], []);
  const user = useUser();
  const sha = useAtomValue(algorithmVersionAtom);
  const { censor } = useCensor();
  const now = useMemo(() => {
    return dayjs.tz();
  }, [user.timezone]);
  const dataWindowStart = useMemo(() => {
    return now.subtract(1, 'week').subtract(5, 'minute').unix();
  }, []);

  const dataWindowEnd = useMemo(() => {
    return now.subtract(5, 'minute').unix();
  }, []);

  const { loading, error, data } = useQuery(DEVICE_GQL, {
    variables: {
      serialCompare: {},
      hwCompare: { _like: '%0.5.%' },
      limit,
      deviceOrderBy: {},
      startTime: dataWindowStart,
      endTime: dataWindowEnd,
      sha,
    },
    skip: !sha,
  });

  if (error) return <span>Error {JSON.stringify(error)}</span>;
  let sortedDevices = [];
  if (data?.device) {
    sortedDevices = [...data.device].sort((a, b) => {
      const a_str = `${a.device_assignments[0]?.feed_line?.farm?.name} ${a.device_assignments[0]?.feed_line?.name}`;
      const b_str = `${b.device_assignments[0]?.feed_line?.farm?.name} ${b.device_assignments[0]?.feed_line?.name}`;

      return a_str.localeCompare(b_str);
    });
  }
  const rows = sortedDevices?.map((device, i) => {
    let hwStatus = <>Unknown</>;
    if (device.hw_faults_agg?.aggregate?.count == 0) {
      hwStatus = <StatusBadge text="No Faults" icon="logo" status="success" />;
    } else if (device.hw_faults_agg?.aggregate?.count > 0) {
      hwStatus = (
        <StatusBadge text={`(${device.hw_faults_agg?.aggregate?.count}) HW Faults`} icon="logo" status="warning" />
      );
    }

    let connectionStatus = <StatusBadge text="Offline" icon="warning" status="error" />;
    if (dayjs.tz(device?.lastTransaction?.[0]?.occured_at * 1000).isAfter(now.subtract(10, 'minute'))) {
      connectionStatus = <StatusBadge text="In Contact" icon="warning" status="warning" />;
    }
    if (dayjs.tz(device?.lastData?.[0]?.created_at * 1000).isAfter(now.subtract(10, 'minute'))) {
      connectionStatus = <StatusBadge text="Catching Up" icon="warning" status="warning" />;
    }
    if (dayjs.tz(device?.lastData?.[0]?.started_at * 1000).isAfter(now.subtract(10, 'minute'))) {
      connectionStatus = <StatusBadge text="Real Time" icon="logo" status="success" />;
    }

    const numberOfFramesTotal = device?.total_feed_frames?.aggregate?.count;
    const numberOfFramesAnalysed = device?.finished_feed_frames?.aggregate?.count;
    let analysisStatus = numberOfFramesTotal === numberOfFramesAnalysed ? 'success' : 'warning';
    if (numberOfFramesTotal - 10 > numberOfFramesAnalysed) analysisStatus = 'error';
    if (numberOfFramesTotal === 0) analysisStatus = 'investigate';

    let statusBadge = (
      <StatusBadge text={`${numberOfFramesAnalysed}/${numberOfFramesTotal}`} icon="logo" status={analysisStatus} />
    );

    return (
      <tr key={device.id}>
        <td>{i + 1}</td>
        <td>
          <Link to={`/b/${device?.device_assignments?.[0]?.feed_line?.farm?.id}/devices`}> {device.serial}</Link>
        </td>
        <td>{device.hw_version}</td>
        <td>
          <Link to={`/b/${device?.device_assignments?.[0]?.feed_line?.farm?.id}`}>
            {censor(device?.device_assignments?.[0]?.feed_line?.farm?.name, censorTypes.barn)}
          </Link>
          ---
          <Link
            to={`/b/${device?.device_assignments?.[0]?.feed_line?.farm?.id}/line/${device?.device_assignments?.[0]?.feed_line?.id}`}
          >
            {censor(device?.device_assignments?.[0]?.feed_line?.name, censorTypes.feedline)}
          </Link>
        </td>
        <td>{hwStatus}</td>
        <td>{connectionStatus}</td>
        <td>{statusBadge}</td>
      </tr>
    );
  });
  const loadingRows = [];
  for (let i = 0; i < 20; i++) {
    loadingRows.push(
      <tr className="loading">
        <td>
          <LoadingSkeleton />
        </td>
        <td>
          <LoadingSkeleton />
        </td>
        <td>
          <LoadingSkeleton />
        </td>
        <td>
          <LoadingSkeleton />
        </td>
        <td>
          <LoadingSkeleton />
        </td>
        <td>
          <LoadingSkeleton />
        </td>
        <td>
          <LoadingSkeleton />
        </td>
      </tr>,
    );
  }

  return (
    <Page className="DeviceStatusPage" titleSegments={pageTitleSegments}>
      <h1>FeedFlo IoT Dashboard</h1>
      <table className="simpleTable">
        <thead>
          <tr>
            <th>#</th>
            <th>Serial</th>
            <th>Hardware Version</th>
            <th>Location</th>
            <th>Hardware Status</th>
            <th>Connectivity (10 min)</th>
            <th>Feed Frames (1 Week)</th>
          </tr>
        </thead>
        <tbody>{loading ? loadingRows : rows}</tbody>
      </table>
    </Page>
  );
}

DeviceStatusPage.propTypes = {
  titleSegments: PropTypes.arrayOf(PropTypes.string),
};

export default DeviceStatusPage;
